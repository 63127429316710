<template>
  <div class="my-container">
    <div class="inner-1000">
    <!-- user.detailUrl -->
      <div class="m-card" :style="user.detailUrl?('background-image:url('+user.detailUrl+');height:413px'):'height:270px'">
        <div class="m-wrap">
          <div class="m-avatar" :style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
          <div class="m-info">
            <div class="m-name">{{ user.name }}</div>
            <div class="m-tag">
              <div>{{user.brief}}</div>
              <span>{{ user.tag1 }}</span>
              <span>{{ user.tag2 }}</span>
            </div>
            <div :class="user.sex == '0' ? 'm-male' : ''"></div>
            <div :class="user.sex == '1' ? 'm-female' : ''"></div>
            <div class="m-btn" @click="href('/my/password')" style="right:150px;">修改密码</div>
            <div class="m-btn" @click="href('/my/edit')">编辑个人资料</div>
          </div>
        </div>
      </div>
      <div class="m-detail">
        <div class="m-header">个人信息</div>
        <div class="m-content">
          <span>姓名：{{ user.name }}</span>
          <!--
		  <span>昵称：{{ user.nickName }}</span>
		  -->
          <span>性别：{{ sexConfig[user.sex] }}</span>
          <span>一级学科：{{ user.firstSubjectName }}</span>
          <span>二级学科：{{ user.secondSubjectName }}</span>
          <span>手机号：{{ user.mobileNo }}</span>
          <span>邮箱：{{ user.email }}</span>
          <span>是否名师：{{user.famousFlag == 'y'?'是':'否'}}</span>
          <span>标签1：{{ user.tag1 }}</span>
          <span>标签2：{{ user.tag2 }}</span>
		  <!--
		  <span>分校名称：{{ user.branchName }}</span>
          <span>教师角色：{{ roleConfig[user.role] }}</span>
          
		  <span>生日：{{ user.birthday }}</span>
          <span>现居住地：{{ user.address }}</span>
          <span>最高学历：{{ eduConfig[user.highEdu] }}</span>
          <span>身份证号：{{ user.idCard }}</span>
          <span>开户行：{{ user.accountBank }}</span>
          <span>户名：{{ user.accountName }}</span>
          <span>银行卡号：{{ user.bankCard }}</span>
          <span>办公电话：{{ user.officePhone }}</span>
          <span>紧急联系人：{{ user.emerContact }}</span>
          <span>紧急联系人电话：{{ user.emerContactPhone }}</span>
          <span>家庭电话：{{ user.homePhone }}</span>
		  -->
        </div>
      </div>
	  <div class="m-detail">
	    <div class="m-header">简介</div>
	    <div class="m-text">{{user.brief}}</div>
	  </div>
      <div class="m-detail">
        <div class="m-header">详情</div>
        <div class="m-text" v-html="user.detail"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      bg: require('@/assets/bg.jpg'),
      user: {},
      sexConfig: {
        0: '男',
        1: '女'
      },
      roleConfig: {
        t: '直播教师',
        a: '助教'
      },
      eduConfig: {
        dzyx: '大专以下',
        dz: '大专',
        bk: '本科',
        yjs: '研究生',
        bsys: '博士及以上'
      }
    };
  },
  computed: {},
  created() {
    // 获取个人信息
    this.getUserInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取个人信息
    getUserInfo() {
      this.get('/biz/teacher/personal/getInfo', {}, e => {
        if (e.code === 200) {
          this.user = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    }
  },
  components: {}
};
</script>

<style></style>
